<script>
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import { executeCustomModuleCall } from '@/services/api/module.api';
import {
  DHME_DRAWING_BOARD,
  DHME_M_ASSEMBLY_HALL_DASHBOARD,
} from '@/modules/modules';
import { downloadDocumentV2 } from '@/services/api/v2/records.v2.api';

export default {
  name: 'DrawingBoard',
  components: { AntInput },
  data: () => {
    return {
      halls: [],
      hallProjects: [],
      focusedHall: null,
      focusedProject: null,
      drawingTab: 'overview',

      projectDrawings: [],
      projectDrawingsTableId: null,
      projectModules: [],
      projectModulesTableId: null,

      displayedDrawing: null,
      focusedDrawingId: null,
      imageLoading: false,
    };
  },
  computed: {
    ...mapGetters(['project']),
    moduleId() {
      return (
        this.project.modules.find(
          (module) => module.route === DHME_DRAWING_BOARD
        )?.id ?? null
      );
    },
  },
  watch: {
    focusedHall(value) {
      if (value) {
        this.fetchProjects();
        const currentQuery = { ...this.$route.query, hall: value.id };
        this.$router.push({
          query: currentQuery,
        });
      } else {
        this.focusedProject = null;
        this.hallProjects = null;
        const currentQuery = { ...this.$route.query };
        delete currentQuery.hall;
        this.$router.push({
          query: currentQuery,
        });
      }
    },

    focusedProject(value) {
      if (value) {
        this.fetchProjectDrawings();
        const currentQuery = { ...this.$route.query, project: value.id };
        this.$router.push({
          query: currentQuery,
        });
      } else {
        const currentQuery = { ...this.$route.query };
        delete currentQuery.project;
        this.$router.push({
          query: currentQuery,
        });
      }
    },
  },
  mounted() {
    this.fetchHalls();
  },
  methods: {
    async fetchProjectDrawings() {
      let { drawings, modules } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_DRAWINGS',
            project: this.focusedProject.id,
            as: 'drawings',
            columns: [
              {
                name: 'title',
              },
              {
                name: 'drawing',
              },
            ],
          },
          {
            name: 'CFFA_DHME_MODULES',
            project: this.focusedProject.id,
            as: 'modules',
            sortBy: 'assembly_sequence',
            columns: [
              {
                name: 'module_id',
              },
              {
                name: 'build_nr',
              },
              {
                name: 'assembly_sequence',
              },
              {
                name: '2D_drawing',
              },
            ],
          },
        ],

      });

      this.projectDrawings = drawings.records;
      this.projectDrawingsTableId = drawings.id;
      this.projectModules = modules.records;
      this.projectModulesTableId = modules.id;
    },
    async fetchHalls() {
      let { halls } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_HALLS',
            project: this.project.id,
            as: 'halls',
            columns: [
              {
                name: 'hall',
                as: 'title',
              },
              {
                name: 'automated',
              },
              {
                name: 'type',
                conditions: [
                  {
                    operator: '=',
                    value: 'assembly',
                  },
                ],
              },
            ],
          },
        ],
      });

      this.halls = halls.records;

      if (this.$route.query.hall) {
        this.focusedHall = this.halls.find(
          (x) => x.id === this.$route.query.hall
        );
      }
    },
    async fetchProjects() {
      this.hallProjects = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchHallProjects',
        { hall: this.focusedHall.id }
      );

      if (this.$route.query.project) {
        this.focusedProject = this.hallProjects.find(
          (x) => x.id === this.$route.query.project
        );
      }
    },
    async displayDrawing(table, recordId, item) {
      this.imageLoading = true;
      this.displayedDrawing = null;
      if (item) {
        try {
          const file = await downloadDocumentV2(table, recordId, item.id);
          this.displayedDrawing =
            URL.createObjectURL(this.b64toBlob(file.file, file.mimetype)) +
            '#navpanes=0&scrollbar=0&view=Fit';
        } catch (e) {}
      }
      this.imageLoading = false;
    },
    b64toBlob(b64Data, contentType) {
      const byteCharacters = atob(b64Data);

      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset + 512),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },
  },
};
</script>

<template>
  <div class="d-flex">
    <div
      class="background-white ant-border-right d-flex flex-column"
      style="width: 400px"
    >
      <div class="py-2 px-5">
        <ant-input label="Hall" style="margin-top: 0 !important">
          <template #input-field>
            <v-select
              v-model="focusedHall"
              :items="halls"
              item-value="id"
              item-text="title"
              placeholder="Hall"
              hide-details
              return-object
              filled
              dense
            >
              <template #item="{ item }">
                {{ item.title }}
                <v-spacer />
                <v-chip class="mr-2" small>
                  <v-icon left small
                    >{{
                      item.automated
                        ? 'mdi-robot-industrial'
                        : 'mdi-account-wrench'
                    }}
                  </v-icon>
                  {{ item.automated ? 'Automated' : 'Manual' }}
                </v-chip>
              </template>
            </v-select>
          </template>
        </ant-input>
        <ant-input label="Project">
          <template #input-field>
            <v-select
              v-model="focusedProject"
              :items="hallProjects"
              item-value="id"
              :disabled="!focusedHall"
              item-text="name"
              placeholder="Project"
              hide-details
              return-object
              filled
              dense
            />
          </template>
        </ant-input>
      </div>
      <div
        v-if="focusedProject"
        class="d-flex flex-column flex-1 flex-scroll-height"
      >
        <v-btn-toggle
          v-model="drawingTab"
          tile
          color="primary"
          group
          mandatory
          class="full-width"
        >
          <v-btn value="overview" class="flex-grow-1"> Overview </v-btn>

          <v-btn value="modules" class="flex-grow-1"> Modules </v-btn>
        </v-btn-toggle>
        <v-list class="flex-grow-1 py-2 px-5 overflow-y-auto" nav>
          <v-list-item-group
            v-if="drawingTab === 'overview'"
            v-model="focusedDrawingId"
            color="primary"
          >
            <v-card
              v-for="item in projectDrawings"
              :key="item.id"
              outlined
              class="mb-2"
            >
              <v-list-item
                outlined
                rounded
                color="primary"
                class="px-4"
                @click="
                  displayDrawing(projectDrawingsTableId, item.id, item.drawing)
                "
              >
                <v-card-text>
                  {{ item.title }}
                </v-card-text>
              </v-list-item></v-card
            >
          </v-list-item-group>
          <v-list-item-group
            v-if="drawingTab === 'modules'"
            v-model="focusedDrawingId"
            color="primary"
          >
            <v-card
              v-for="module in projectModules"
              :key="module.id"
              class="mb-2"
              outlined
            >
              <v-list-item
                outlined
                rounded
                color="primary"
                three-line
                class="px-4"
                @click="
                  displayDrawing(
                    projectModulesTableId,
                    module.id,
                    module['2d_drawing']
                  )
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    >Module: {{ module.module_id }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    Bouwnummer: {{ module.build_nr }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Assemblage volgorde: {{ module.assembly_sequence }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">{{
                      module['2d_drawing'] ? 'mdi-image' : 'mdi-image-off'
                    }}</v-icon>
                  </template>
                  <span>{{
                    module['2d_drawing'] ? 'Image found' : 'No image found'
                  }}</span>
                </v-tooltip>
              </v-list-item>
            </v-card>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <div class="flex-grow-1 d-flex flex-column">
      <v-progress-linear v-show="imageLoading" indeterminate color="primary" />
      <div class="flex-grow-1 d-flex align-center justify-center">
        <iframe
          v-if="displayedDrawing"
          :src="displayedDrawing"
          type="application/pdf"
          height="100%"
          width="100%"
        />
        <span v-else>No image found</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.focused {
  border: solid 1px var(--v-primary-base);
}
</style>
